<template>
  <img
    :src="actualSrc"
    :alt="alt"
    :style="imageCss"
    :class="imageClass"
    @error="() => internalSrc = fallback"
  />
</template>

<script>
import FallbackSrc from '@/assets/no-image.png';

export default {
  name: 'ImageOrFallback',
  props: {
    fallback: {
      type: String,
      default: FallbackSrc
    },
    src: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      default: 'Image'
    },
    height: {
      type: String,
      default: '48px'
    },
    "max-height":{
      type: String,
    },
    width: {
      type: String,
      default: '48px'
    },
    "max-width":{
      type: String,
    },
    "object-fit":{
      type:String,
      default:"cover"
    },
    imageClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalSrc: this.src
    };
  },
  computed: {
    actualSrc () {
      return this.internalSrc || this.fallback;
    },
    imageCss() {
      return {
        height: this.height,
        width: this.width,
       "max-height":this.maxHeight,
       "max-width":this.maxWidth,
       "object-fit":this.objectFit

      };
    }
  },
  watch: {
    src () {
      this.internalSrc = this.src;
    }
  }
}
</script>