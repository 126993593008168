<template>
  <div id="app-edit">
    <h1 class="uk-text-left"><a @click="$router.go(-1)" style="text-decoration: none;" class=""><span uk-icon="icon: chevron-left; ratio: 2.2"></span> <span>{{ $t('Pages.AppEdit.Title') }}</span></a></h1>
    <hr />
    <ul uk-tab id='app-edit-tabs'>
      <li class="uk-active" @click="selectedTab = 'profile'"><a href="">{{ $t( 'Pages.Application.Profile' ) }}</a></li>
      <li @click="selectedTab = 'assets'"><a href="">{{ $t( 'Pages.Application.Assets' ) }}</a></li>
      <li @click="selectedTab = 'notifications'"><a href="">{{ $t( 'Pages.Application.NotificationAndTokens' ) }} <span class="uk-text-danger" v-if="applicationPushAndTokensUnsaved"> *</span></a></li>
      <li @click="selectedTab = 'developer'"><a href="">{{ $t( 'Pages.Application.Developer' ) }}</a></li>
    </ul>
    <ul v-if="!loading" class="uk-switcher uk-margin uk-margin-medium-top">
      <li>
        <div class="">
          <ApplicationAddEdit 
            :appData="application"
            :selectedTab="selectedTab"
          />
        </div>
      </li>
      <li>
        <AppAssets 
         :appData="application"/>
      </li>
      <li>
        <div class="">
          <ApplicationPushAndTokens
            :appData="application"
            :selectedTab="selectedTab"
            @formChanged="updateFormChange('applicationPushAndTokensUnsaved', $event)"
          />
        </div>
      </li>
      <li>
        <div class="">
          <ApplicationDeveloper
            :appData="application"
            :selectedTab="selectedTab"
          />
        </div>
      </li>
    </ul>
    <div v-else><span uk-spinner></span></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApplicationAddEdit from '@/components/forms/ApplicationAddEdit';
import ApplicationPushAndTokens from '@/components/forms/ApplicationPushAndTokens';
import ApplicationDeveloper from '@/components/forms/ApplicationDeveloper';
import AppAssets from '@/views/admin/AppAssets'

export default {
  name: 'AppEdit',
  components: {
    ApplicationAddEdit,
    ApplicationPushAndTokens,
    ApplicationDeveloper,
    AppAssets
  },
  data() {
    return {
      loading: false,
      selectedTab: this.$route.query?.tab ||  'profile',
      applicationPushAndTokensUnsaved: false
    };
  },
  computed: {
    ...mapState({
      application: state => state.venom.selected.application || {}
    })
  },
  methods: {
    updateFormChange (key, event) {
      if (typeof event === 'boolean') {
        this[key] = event
      }
    },
    showTab (tab) {
      const tabs = {
        profile: 0,
        assets: 1,
        notifications: 2,
        developer: 3
      }
      const element = document.getElementById('app-edit-tabs')
      window.UIkit.tab(element).show(tabs[tab])
    },
    async selectApplication() {
      const appId = this.$route.params.appId;
      await this.$store.dispatch('selectApplication', { appId });
    }
  },
  async created() {
    this.loading = true
    await this.selectApplication();
    this.loading = false
  },
  mounted () {
    document.documentElement.scrollTop = 0
    this.showTab(this.selectedTab)
  },
  watch: {
    selectedTab: {
      handler () {
        const url = new URL(window.location);
        url.searchParams.set('tab', this.selectedTab);
        window.history.replaceState({}, '', url);
      }
    }
  },
}
</script>
