<template>

  <div id="image-upload-modal" class="-uk-flex-top" uk-modal="bg-close: false; esc-close: false">
    <div class="uk-modal-dialog uk-margin-auto-vertical" uk-overflow-auto>
        <div class="uk-modal-header">
            <h3 class="uk-modal-title uk-text-left uk-margin-remove-bottom"> {{$t('Pages.MediaLibrary.UploadMedia') }} </h3>
            <h5 v-if="subtitle" class="uk-width-1-1 uk-text-muted uk-text-left uk-margin-small-top">{{subtitle}}</h5>
        </div>

        <div class="uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <InputUploader @start="uploading = true" @complete="onUploadComplete" @cancel="onCancel" :multiple="false" :accept="`image/*`"/>
        </div>

    </div>
  </div>
    
</template>

<script>
import InputUploader from '@/views/admin/mediaLibrary/InputUploader';

export default {
  name: 'ImageUploadComponent',
  components: {
    InputUploader
  },
  props: {
    subtitle: String
  },
  data () {
    return {
      uploading: false
    }
  },
  methods: {
    onUploadComplete (files) {
      this.$emit( 'complete', files && files[0] ); 
      this.$children[0].startedFiles = [];
    },
    onCancel () {
      this.$children[0].startedFiles = [];
      return null
    }
  }
}
</script>
