<template>
  <div>
    <div class="uk-child-width-expand@s uk-grid-divider" uk-grid>
      <form class="uk-form-horizontal uk-margin-large uk-text-left uk-margin-remove-bottom" @submit.prevent="updateAppData();">
        <div>
          <span class="uk-text-large">{{ $t( 'Pages.Application.AppDetail' ) }} </span>
          <span class="uk-text-danger" v-if="formChanged">{{$t('Generic.Labels.Unsaved')}}</span>
          <div class="uk-margin-small">
            <label class="uk-form-label custom-label-margin" for="app-id">{{ $t( 'Pages.Application.AppId' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-id" type="text" :value="appData.id" disabled>
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-platform">{{ $t( 'Pages.Application.Platform' ) }}</label>
            <div class="uk-form-controls">
              <select class="uk-select" id="app-platform" v-model="form.app.type" disabled="true" style="border: 1px solid var(--border-color); display: none;">
                <option
                  v-for="(device, di) in availableDevices"
                  :key="di"
                  :value="device"
                >
                  {{ $t( `enums.DeviceType.${device}` ) }}
                </option>
              </select>
              <span class="uk-input" style="border: none;">{{ $t( `enums.DeviceType.${form.app.type}` ) }}</span>
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-name">{{ $t( 'Pages.Application.AppName' ) }} <span class="uk-text-danger">*</span></label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input class="uk-input" id="app-name" type="text" 
                  :placeholder="$t( 'Pages.Application.Placeholder.AppName' )" v-model.trim="form.app.name"
                  :class="{'invalid-border': !form.valid.name }" @keyup="validateInput( 'name' )"
                >
                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.valid.name" uk-icon="icon: warning" ></span>
                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.valid.name" > {{ $t( 'Generic.Errors.Min2Chars' ) }}</span>
              </div>
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-description">{{ $t( 'Pages.Application.AppDescription' ) }}</label>
            <div class="uk-form-controls">
              <textarea class="uk-textarea" id="app-description"
                :placeholder="$t( 'Pages.Application.Placeholder.AppDescription' )" v-model.trim="form.app.description"></textarea>
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-version">{{ $t( 'Pages.Application.VersionNumber' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-version" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.VersionNumber' )" v-model.trim="form.app.currentVersion">
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-properties">{{ $t( 'Pages.Application.AssignProperties' ) }}</label>
            <div class="uk-form-controls uk-position-relative">
              <Multiselect
                :dataSet="appPropertiesForMultiselect || []"
                v-model="form.app.selectedProperties"
                :resetForm="resetForm"
                :placeholder="$t('Pages.Application.SelectAppProps')"
              />
            </div>
          </div>
          <hr>
          
          <span class="uk-text-large">{{ $t( 'Pages.Application.AppConfig' ) }}</span>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-analytics-id">{{ $t( 'Pages.Application.AnalyticsID' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-analytics-id" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.AnalyticsID' )" v-model.trim="form.app.amplitudeProjectId">
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-analytics-key">{{ $t( 'Pages.Application.AnalyticsKey' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-analytics-key" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.AnalyticsKey' )" v-model.trim="form.app.amplitudeKey">
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-bundle-id">{{ $t( 'Pages.Application.BundleID' ) }}</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input class="uk-input" id="app-bundle-id" type="text" 
                  :placeholder="$t( 'Pages.Application.Placeholder.BundleID' )" v-model.trim="form.app.bundleId"
                  :class="{'invalid-border': !form.valid.bundleId }" @keyup="validateInput( 'bundleId' )">
                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.valid.bundleId" uk-icon="icon: warning" ></span>
                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.valid.bundleId" > {{ $t( 'Generic.Errors.Min2Chars' ) }}</span>
              </div>
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-store-url">{{ $t( 'Pages.Application.StoreUrl' ) }} <span v-if="wasStoreUrlPresent" class="uk-text-danger">*</span> </label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input class="uk-input" id="app-store-url" type="text" 
                  :placeholder="$t( 'Pages.Application.Placeholder.StoreUrl' )" v-model.trim="form.app.storeUrl"
                  :class="{ 'invalid-border': !focused.storeUrl && !form.valid.storeUrl }" @keyup="validateInput( 'storeUrl' )"
                  @focus="focused.storeUrl = true" @blur="focused.storeUrl = false"
                  >
                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!focused.storeUrl && !form.valid.storeUrl" uk-icon="icon: warning" ></span>
                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!focused.storeUrl && !form.valid.storeUrl" > {{ $t('Generic.Errors.InvalidUrl') }}</span>
              </div>
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-passcode">{{ $t( 'Pages.Application.TestingPasscode' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-passcode" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.TestingPasscode' )" v-model.trim="form.app.debugPasscode">
            </div>
          </div>
          
          <hr class="uk-margin-top" />
          <div class="uk-text-center">
            <span class="uk-text-danger" v-if="!isFormValid && saveClicked">{{$t('Generic.Errors.PageHasErrors')}}
              <span class="" uk-icon="icon: warning"></span>
            </span>
            <br>
            <button v-if="!saving" class="uk-button uk-button-primary uk-margin-small" type="button" @click.prevent="updateAppData();">{{ $t( 'Actions.Save' ) }}</button>
            <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
          </div>
        </div>
      </form>
          
      <div class="uk-width-1-3">
        <h3>{{$t('Pages.AppEdit.Labels.Icon')}}</h3>
        <div class="uk-container">
            <ImageOrAbbrevation
             class="uk-margin-bottom"
              :src="getImgUrl"
              :alt="form.app.name"
              height="200px"
              width="200px"
              :imageClass="imageClass"
            />
          <button class="uk-button uk-button-primary uk-margin-small-right uk-margin-small-left uk-width-small" uk-toggle="target: #image-upload-modal" >{{$t('Actions.Change')}}</button>
          <ImageUploadModal @complete="onUploadComplete" />
        </div>
      </div>

      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Notification from '@/components/util/Notification';
import { DeviceType } from '@/utils/enums.js';
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import ImageUploadModal from '@/components/modals/ImageUploadModal';
import Multiselect from '@/components/multiselect/Multiselect';

const validUrlPattern = /^(http(s)?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; // eslint-disable-line

export default {
  name: 'AppsView',
  components: {
    ImageOrAbbrevation,
    ImageUploadModal,
    Multiselect
  },
  props: {
    appData: {
      type: Object
    }
  },
  data() {
    return {
      availableDevices: DeviceType.valuesAlphabetical,
      detectChange: false,
      formAppDataSignature: '',
      formChanged: false,
      imageClass: 'uk-border-circle',
      iconPreviewUrl: '',
      resetForm: 0,
      saveClicked: false,
      wasStoreUrlPresent: false,
      focused: {
        storeUrl: false
      },
      form: {
        app: {
          amplitudeKey: this.appData.amplitude?.key || '',
          amplitudeProjectId: this.appData.amplitude?.projectId || '',
          bundleId: this.appData?.bundleId,
          currentVersion: this.appData?.store?.currentVersion,
          debugPasscode: this.appData?.debugPasscode,
          description: this.appData?.description,
          name: this.appData?.name,
          properties: this.appData.properties ? this.appData.properties.map(a => a.id) : [],
          selectedProperties: [],
          storeUrl: this.appData?.store?.url || null,
          type: this.appData?.type,
          icon: this.appData?.icon,
        },
        valid: {
          name: true,
          bundleId: true,
          storeUrl: true
        }
      },
      saving: false,
      orgId: this.$route.params.id
    };
  },
  watch: {
    // set a watcher for the data/computed variable to update for dynamic values
    appData() {
      this.detectChange = false
      this.form = {
        app: {
          amplitudeKey: this.appData.amplitude?.key || '',
          amplitudeProjectId: this.appData.amplitude?.projectId || '',
          bundleId: this.appData.bundleId,
          currentVersion: this.appData.store.currentVersion,
          debugPasscode: this.appData.debugPasscode,
          description: this.appData.description,
          name: this.appData.name,
          properties: this.appData.properties.map(a => a.id),
          selectedProperties: [],
          storeUrl: this.appData.store.url || null,
          type: this.appData.type,
          icon: this.appData?.icon
        },
        valid: {
          name: true,
          bundleId: true,
          storeUrl: true
        }
      }
      if (this.appData.store.url) {
        this.wasStoreUrlPresent = true
      }
      // Timeout for the properties to load
      setTimeout(() => {
        this.formAppDataSignature = this.getSignature()
        this.formChanged = false
        this.detectChange = true
      }, 200)
    },
    form: {
      handler () {
        if (this.detectChange && this.formAppDataSignature) {
          process.nextTick(() => {
            const newSignature = this.getSignature()
            if (newSignature !== this.formAppDataSignature) {
              this.formChanged = true
            } else {
              this.formChanged = false
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      appProperties: state => state.venom.org?.appProperties || []
    }),
    getOrgId() {
      return ( this.orgId && this.orgId === this.$route.params.id ) ? this.orgId : this.$route.params.id;
    },  
    getImgUrl () {
      return this.iconPreviewUrl || this.appData.icon?.original?.url
    },
    appPropertiesForMultiselect () {
      const appProperties = []
      this.appProperties?.forEach(prop => {
        appProperties.push({
          value: prop.id,
          label: prop?.i18nName?.localized?.value,
          selected: this.form.app?.properties?.includes(prop?.id)
        })
      })
      return appProperties
    },
    selectedAppProperties () {
      return this.form.app.selectedProperties?.map(prop => prop.value)
    },
    isFormValid () {
      return this.form.valid.name && this.form.valid.bundleId && this.form.valid.storeUrl
    }
  },
  mounted() {
    this.getAppProperties();
  },
  beforeDestroy () {
    const modalElement = document.getElementById('image-upload-modal')
    if (modalElement) {
      window.UIkit.modal('#image-upload-modal').$destroy(true);
    }
  },
  methods: {
    getSignature () {
      const signObj = Object.assign({}, this.form.app)
      delete signObj.icon
      return JSON.stringify(signObj)
    },
    async getAppProperties() {
      await this.$store.dispatch( 'getAppProperties', {
        orgId: this.orgId
      } )
    },
    propertyName( property ) {
      return property?.i18nName?.localized?.value || 'N/A';
    },
    validateInput( field ) {
      let isValid = false;
      if ( field === 'name' && this.form.app.name.length >= 2 ) {
        isValid = true;
      } else if ( field === 'bundleId' && (!this.form.app.bundleId || this.form.app.bundleId.length >= 2)) {
        isValid = true;
      } else if ( field === 'storeUrl' && ((this.form.app.storeUrl && validUrlPattern.test(this.form.app.storeUrl)) || (!this.form.app.storeUrl && !this.wasStoreUrlPresent))) {
        isValid = true;
      }
      this.form.valid[ field ] = isValid;
      return isValid;
    },
    async updateAppData() {
      this.saveClicked = true
      const isValidName = this.validateInput( 'name' );
      const isValidBundleId = this.validateInput( 'bundleId' );
      const isValidStoreUrl = this.validateInput( 'storeUrl' );
      if ( isValidName && isValidBundleId  && isValidStoreUrl && !this.saving ) {
        this.saving = true;
        const params = {  
          appId: this.appData.id,
          inputFields: {
            type: this.form.app.type,
            name: this.form.app.name,
            description: this.form.app.description,
            properties: this.selectedAppProperties || [],
            amplitude: {
              key: this.form.app.amplitudeKey,
              projectId: this.form.app.amplitudeProjectId
            },
            debugPasscode: this.form.app.debugPasscode,
            bundleId: this.form.app.bundleId
          }
        }
        // if (this.form.app.storeUrl) {
          params.inputFields.store = {
            currentVersion: this.form.app.currentVersion,
            url: this.form.app.storeUrl || null
          // }
        }
        const response = await this.$store.dispatch( 'updateApplication', params);
        this.saving = false;
        if( response?.updateApplication?.application?.id ) {
          process.nextTick(() => {
            this.formAppDataSignature = this.getSignature()
            this.formChanged = false
          })
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Application.Notification.AppUpdated' ) );
        } else {
          Notification.showNotification( this.$t( 'Generic.Messages.UnSuccessful' ), response?.errors[0]?.message, 'error' );
        }
      }
    },
    async onUploadComplete (file) {
      window.UIkit.modal('#image-upload-modal').hide();
      const logoUpdateParams = {
        inputFields: {
          icon: {
            key: file.path
          },
        },
        appId: this.appData.id
      }
      this.saving = true;
      const response = await this.$store.dispatch('updateApplication', logoUpdateParams);
      this.saving = false;
      if (response) {
        this.iconPreviewUrl = file.previewUrl;
        Notification.showNotification( this.$t( 'Pages.AppEdit.Success.AppUpdated' ), this.$t( 'Pages.AppEdit.Success.IconUpdated' ) );
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  color: #e69393;
}
.select-options {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>