import { render, staticRenderFns } from "./ImageOrAbbrevation.vue?vue&type=template&id=12b6a51b&"
import script from "./ImageOrAbbrevation.vue?vue&type=script&lang=js&"
export * from "./ImageOrAbbrevation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports