<template>
  <div>
    <ImageOrFallback
      :src="imageSource"
      :alt="alt"
      :height="height"
      :width="width"
      :imageClass="imageClass"
    />
  </div>
</template>

<script>
import { getTwoCharacterAbbreviation, getPlaceholderImageWithText } from '@/components/util/Strings';
import ImageOrFallback from '@/components/images/ImageOrFallback';
import { mapState } from 'vuex';

export default {
  name: 'ImageOrAbbrevation',
  components: {
    ImageOrFallback
  },
  props: {
    src: {
      type: String
    },
    alt: {
      type: String,
      required: false
    },
    height: {
      type: String,
      default: '48px'
    },
    width: {
      type: String,
      default: '48px'
    },
    imageClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      theme: state => state.venom.theme
    }),
    abbrev () {
      return this.alt && this.alt.length >= 2 ? getTwoCharacterAbbreviation( this.alt ) : this.$t('Actions.Loading');
    },
    fallbackSrc () {
      return getPlaceholderImageWithText( this.abbrev, this.height.replace(/[^0-9]/g,''), this.width.replace(/[^0-9]/g,''), this.theme === 'dark' ? '23232399': 'ededed', this.theme === 'dark' ? 'ededed': '23232399' );
    },
    imageSource () {
      if ( this.src ) {
        return this.src;
      } else {
        return this.fallbackSrc;
      }
    }
  }
}
</script>