<template>
  <form class="uk-form-horizontal uk-margin-large uk-text-left uk-margin-remove-bottom">
    <div class="uk-child-width-expand@s uk-grid-divider" uk-grid="masonry: true">
      <div class="uk-width-1-2@m">
        <span class="uk-text-large">{{ $t( 'Pages.Application.PushNotificationConfig' ) }} ({{ $t( `enums.DeviceType.${appType}` ) }})</span>
        <span class="uk-text-danger uk-margin-small-left" v-if="formChanged">{{$t('Generic.Labels.Unsaved')}}</span> 
        <div v-if="appType === 'IOS'" class="uk-margin-top">
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-ios-private-key">{{ $t( 'Pages.Application.iOSPushPrivateKey' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-ios-private-key" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.iOSPushPrivateKey' )" v-model.trim="form.push.ios.privateID">
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-ios-api-key">{{ $t( 'Pages.Application.iOSPushApiKey' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-ios-api-key" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.iOSPushApiKey' )" v-model.trim="form.push.ios.apiKey">
            </div>
          </div>
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-ios-team-id">{{ $t( 'Pages.Application.iOSPushTeamId' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-ios-team-id" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.iOSPushTeamId' )" v-model.trim="form.push.ios.teamId">
            </div>
          </div>
          <hr class="uk-margin-top" />
          <div class="uk-text-right">
            <button v-if="showPushNotification" class="uk-button uk-button-secondary uk-border-pill uk-margin-right" uk-toggle="target: #push-notification-modal" type="button">{{ $t( 'Actions.PushNotifications' ) }}</button>
            <button v-if="!saving" class="uk-button uk-button-primary" type="button" @click.prevent="savePushConfig('ios');">{{ $t( 'Actions.Save' ) }}</button>
            <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
          </div>
        </div>
        <div v-else-if="appType !== 'HTML5'" class="uk-margin-top">
          <div class="uk-margin-small">
            <label class="uk-form-label" for="app-android-private-key">{{ $t( 'Pages.Application.AndroidPushApiKey' ) }}</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="app-android-private-key" type="text" 
                :placeholder="$t( 'Pages.Application.Placeholder.AndroidPushApiKey' )" v-model="form.push.android.apiKey">
            </div>
          </div>
          <hr class="uk-margin-top" />
          <div class="uk-text-right">
            <button v-if="showPushNotification" class="uk-button uk-button-secondary uk-border-pill uk-margin-right" uk-toggle="target: #push-notification-modal" type="button">{{ $t( 'Actions.PushNotifications' ) }}</button>
            <button v-if="!saving" class="uk-button uk-button-primary" type="button" @click.prevent="savePushConfig('android');">{{ $t( 'Actions.Save' ) }}</button>
            <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
          </div>
        </div>
        <div v-else>
          <div class="uk-margin-top">
            <p class="uk-text-meta">{{ $t( 'Pages.Application.PushNotificationNotSupported' ) }}</p>
          </div>
        </div>
      </div>
      <div class="uk-width-1-2@m">
        <div class="uk-text-center">
          <p class="uk-text-large">{{ $t( 'Pages.Application.AccessTokens' ) }}</p>
          <dl
            v-for="(token, i) in appData.tokens" 
            :key="i"
            class="uk-description-list"
          >
            <dd>{{ token.value }}</dd>
            <dd class="uk-text-meta">{{ token.createdAt | date }}</dd>
          </dl>
          <button class="uk-button uk-button-primary" type="button" @click.prevent="generateNewToken();">{{ $t( 'Actions.GenerateNewToken' ) }}</button>
        </div>
      </div>
      <div>
      <push-notification-modal :appSelection="'disabled'" />
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import Notification from '@/components/util/Notification';
import PushNotificationModal from '@/components/modals/PushNotificationModal';

export default {
  name: 'AppsPushAndTokensView',
  components: {
    PushNotificationModal
  },
  props: {
    appData: {
      type: Object
    },
    selectedTab: {
      type: String
    }
  },
  beforeDestroy () {
    const modalElement = document.getElementById('push-notification-modal')
    if (modalElement) {
      process.nextTick(() => {
        window.UIkit.modal('#push-notification-modal').$destroy(true);
      })
    }
  },
  data() {
    return {
      detectChange: false,
      formSignature: '',
      formChanged: false,
      form: {
        push: {
          ios: {
            privateID: '',
            apiKey: '',
            teamId: ''
          },
          android: {
            apiKey: ''
          }
        },
        valid: {
          ios: {
            privateID: true,
            apiKey: true,
            teamId: true
          },
          android: {
            apiKey: true
          }
        }
      },
      saving: false
    };
  },
  watch: {
    // set a watcher for the data/computed variable to update for dynamic values
    appData() {
      this.form = {
        push: {
          ios: {
            privateID: this.appData?.pushNotificationConfig?.internal?.privateKey || '',
            apiKey: this.appData?.pushNotificationConfig?.internal?.keyId || '',
            teamId: this.appData?.pushNotificationConfig?.internal?.teamId || ''
          },
          android: {
            apiKey: this.appData?.pushNotificationConfig?.internal?.apiKey || ''
          }
        },
        valid: {
          ios: {
            privateID: true,
            apiKey: true,
            teamId: true
          },
          android: {
            apiKey: true
          }
        }
      };
      this.$nextTick(() => {
        this.formSignature = this.getSignature()
        this.formChanged = false
        this.detectChange = true
      })
    },
    selectedTab() {
      if ( this.selectedTab === 'notifications' ) {
        const form = this.form
        this.detectChange = false
        this.form = {
          push: {
            ios: {
              privateID: form.push.ios.privateID || this.appData?.pushNotificationConfig?.internal?.privateKey || '',
              apiKey: form.push.ios.apiKey || this.appData?.pushNotificationConfig?.internal?.keyId || '',
              teamId: form.push.ios.teamId || this.appData?.pushNotificationConfig?.internal?.teamId || ''
            },
            android: {
              apiKey: form.push.android.apiKey || this.appData?.pushNotificationConfig?.internal?.apiKey || ''
            }
          },
          valid: {
            ios: {
              privateID: form.valid.ios.privateID || true,
              apiKey: form.valid.ios.apiKey || true,
              teamId: form.valid.ios.teamId || true
            },
            android: {
              apiKey: form.valid.android.apiKey || true
            }
          }
        };
        this.$nextTick(() => {
          this.formSignature = this.getSignature()
          this.detectChange = true
        })
      }
    },
    form: {
      handler () {
        if (this.detectChange && this.formSignature) {
          process.nextTick(() => {
            const newSignature = this.getSignature()
            if (newSignature !== this.formSignature) {
              this.formChanged = true
              this.$emit( 'formChanged', this.formChanged)
            } else {
              this.formChanged = false
              this.$emit( 'formChanged', this.formChanged)
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    appType() {
      return this.appData?.type || '';
    },
    showPushNotification() {
      if (!this.featureSupport?.pushNotifications) return false
      if ( this.appData?.pushNotificationConfig?.internal?.keyId || this.appData?.pushNotificationConfig?.internal?.apiKey ) {
        return true;
      }
      return false;
    },
    ...mapState({
      viewer: state => state.venom.org.viewer,
      featureSupport: state => state.venom.org.options?.featureSupport
    }),
  },
  methods: {
    getSignature () {
      const signObj = Object.assign({}, this.form.push)
      return JSON.stringify(signObj)
    },
    async savePushConfig( platform ) {
      this.saving = true;
      let inputFields = '';
      if ( platform === 'ios' ) {
        if ( this.form.push.ios.apiKey.length >= 2 && this.form.push.ios.privateID.length >= 2 && this.form.push.ios.teamId.length >= 2 ) {
          inputFields = {
            pushNotificationConfig: {
              internal: {
                iOS: {
                  keyId: this.form.push.ios.apiKey,
                  privateKey: this.form.push.ios.privateID,
                  teamId: this.form.push.ios.teamId
                }
              }
            }
          };
        }
      } else {
        if ( this.form.push.android.apiKey.length >= 2 ) {
          inputFields = {
            pushNotificationConfig: {
              internal: {
                android: {
                  apiKey: this.form.push.android.apiKey
                }
              }
            }
          };
        }
      }
      if ( !inputFields ) {
        Notification.showNotification( this.$t( 'Pages.Application.Notification.Alert' ), this.$t( 'Pages.Application.Notification.RequiredFieldsMissing' ), 'error' );
      } else {
        const appId = await this.$store.dispatch( 'updateApplication', {
          appId: this.appData.id,
          inputFields
        } );
        if( appId ) {
          process.nextTick(() => {
            this.formAppDataSignature = this.getSignature()
            this.formChanged = false
          })
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Application.Notification.AppUpdated' ) );
        }
      }
      this.saving = false;
    },
    async generateNewToken() {
      await this.$store.dispatch( 'generateToken', {
        appId: this.appData.id
      } );
      Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Application.Notification.NewTokenCreated' ) );
    }
  }
}
</script>