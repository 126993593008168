function makeEnum ( enumName, arr = []) {
    const toIdentifier = name => name.replace( /[^a-zA-Z0-9_]/g, '' );

    const enumObj = arr.reduce( ( obj, val ) => {
        if ( Array.isArray( val ) )
            return Object.assign( obj, { [val[0]]: val[1] });
        if ( typeof val === 'string' )
            return Object.assign( obj, { [toIdentifier( val )]: val });
        return obj;
    }, {});

    const values = Object.values( enumObj );

    Object.defineProperties( enumObj, {
        name: { value: enumName },
        values: { value: values },
        valuesAlphabetical: { value: values.sort() },
        [Symbol.toStringTag]: { value: `[Enum ${enumName}: ${values.join( ' | ' )}]` },
        has: { value: val => values.includes( val ) }
    });

    return new Proxy( enumObj, {
        get ( target, prop, receiver ) {
            const existing = Reflect.get( target, prop, receiver );
            if ( !existing )
                throw new Error( `Enum ${enumName} does not have value ${prop?.toString()}.`
                    + ` Valid values are [ ${values.join( ' | ' )} ]` );
            return existing;
        },

        set () {
            throw new Error( `Enum ${enumName} cannot be modified after being created.` );
        }
    });
}

export const MediaClass = makeEnum( 'MediaClass', [
    'SUBTITLES',
    'VIDEO',
    'AUDIO',
    'IMAGE',
    'FILE',
    'MODEL',
    'LIVE'
]);

export const DeviceType = makeEnum( 'DeviceType', [
    'IOS',
    'ANDROID',
    'GEARVR',
    'OCULUS_RIFT',
    'HTML5',
    'PSVR',
    'HTC_VIVE',
    'DAYDREAM',
    'OCULUS_GO',
    'OCULUS_QUEST',
    'HUAWEI',
    'PICO'
]);

export const ProjectionType = makeEnum( 'ProjectionType', [
    'FLAT',
    'CUBE_MAP',
    'TILED_CLEARVR',
    'EQUIRECTANGULAR',
    'EQUIRECTANGULAR_3D_L_OVER_R',
    'EQUIRECTANGULAR_3D_R_OVER_L',
    'EQUIRECTANGULAR_3D_SIDE_BY_SIDE',
    'EQUIRECTANGULAR_180',
    'EQUIRECTANGULAR_180_3D_R_OVER_L',
    'EQUIRECTANGULAR_180_3D_L_OVER_R',
    'EQUIRECTANGULAR_180_3D_SIDE_BY_SIDE',
    'EQUISOLID_FISHEYE'
]);

export const ContentType = makeEnum( 'ContentType', [
    'AD',
    'VOD',
    'STREAM',
    'INTERACTIVE',
    'COLLECTION',
    'AR'
]);

export const MediaTranscodeType = makeEnum( 'MediaTranscodeType', [ 'LOWER_RESOLUTION_MP4S', 'HLS_ABR', 'CLEARVR' ]);

export const ClearvrTranscodeJobEncodeQualityTypes = makeEnum( 'ClearvrTranscodeJobEncodeQualityType', [
    'DEFAULT',
    'MEDIUM',
    'HIGH',
    'VERY_HIGH',
    'FIXED_LOW',
    'FIXED_STANDARD',
    'FIXED_HIGH',
    'TRIPLE',
    'TRIPLE_HIGH'
]);

export const Permissions = makeEnum('PermissionTypes', ['ADMIN', 'NONE', 'VIEWER', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER'])

export const JobStatusType = makeEnum( 'JobStatusType', [ 'NONE', 'IN_PROGRESS', 'COMPLETED', 'ERROR' ]);

export const BlindspotType = makeEnum( 'BlindspotType', [ 'NONE', 'BOTTOM', 'TOP' ]);

export const AccessType = makeEnum( 'AccessType', [ 'PRIVATE', 'TESTING', 'PUBLIC' ]);

export const ContentAdTypes = makeEnum( 'ContentAdTypes', [ 'AUTO', 'DISABLED', 'SPECIFIC_ADS' ]);

export function checkEnumExists(enumArray, value) {
  return Object.values(enumArray).includes(value);
}