
export function getTwoCharacterAbbreviation ( str ) {
  if ( str.trim().length === 0 )
    return str;

  const words = str.trim().split( ' ' );
  if ( words.length >= 2 ) {
    return words[0][0].toUpperCase() + words[1][0].toUpperCase();
  }
  return words[0][0].toUpperCase() + words[0][1].toUpperCase();
}

export function getPlaceholderImageWithText ( text, height = 48, width = 48, background, color, format = '.png' ) {
  const url = `https://via.placeholder.com/${width}x${height}/${background}/${color}/${format}?text=${text}`;
  return url;
}