<template>
  <div class="uk-text-left">
    <div class="uk-child-width-expand@s" uk-grid>
      <div>
        <span class="uk-text-large">{{ $t('Pages.Application.DeveloperKey') }}</span>
      </div>
      <div>
        <button class="uk-button uk-button-primary uk-align-center uk-float-right" href="#modal-newappkey" @click="toggleKeyVisibility('private')" uk-toggle>
          {{ $t('Actions.NewAppDeveloperKey') }}
        </button>
      </div>
    </div>
    <table class="uk-table uk-table-justify uk-table-divider">
      <thead>
        <tr>
          <th>{{ $t( 'Pages.Application.TableHeading.DevKeyName' ) }}</th>
          <th>{{ $t( 'Pages.Application.TableHeading.DevKeyValue' ) }}</th>
          <th class="uk-width-small">{{ $t( 'Pages.Application.TableHeading.DevKeyVisibility' ) }}</th>
          <th class="uk-width-small uk-text-center">{{ $t( 'Pages.Application.TableHeading.Actions' ) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="devKeys.length == 0">
          <td colspan="4" class="uk-text-center">{{ $t( 'Pages.Application.NoRecordsFound' ) }}</td>
        </tr>
        <tr 
          v-else
          v-for="(devKey, i) in devKeys"
          :key="i"
        >
          <td class="uk-text-truncate">{{ devKey.key }}</td>
          <td class="uk-text-truncate">{{ devKey.value }}</td>
          <td>{{$t('Pages.Content.Availability.' + devKey.visibility.charAt(0) + devKey.visibility.toLowerCase().slice(1)) }}</td>
          <td class="uk-text-center">
            <a href="" class="uk-margin-small-right" uk-icon="pencil" @click.prevent="editAppKey( i )"></a>
            <a href="" class="uk-margin-small-right" uk-icon="trash" @click.prevent="deleteAppKey( i )"></a>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="modal-newappkey" uk-modal bg-close="false" class="">
      <div class="uk-modal-dialog" style="min-width: 700px">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ $t( 'Pages.Application.DevKeyTitle' ) }}</h2>
        </div>
        <div class="uk-modal-body">
          <form class="uk-form-horizontal uk-margin-large uk-text-left" @submit.prevent="saveNewApp();">
            <div class="uk-margin-small">
              <label class="uk-form-label" for="app-key-name">{{ $t( 'Pages.Application.DevKeyName' ) }} <span class="uk-text-danger">*</span></label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input class="uk-input" id="app-key-name" type="text" v-model.trim="form.app.key"
                    :placeholder="$t( 'Pages.Application.Placeholder.DevKeyName' )"
                    :class="{ 'invalid-border': !form.valid.key }" @keyup="validateInput( 'key' )">
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.valid.key" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.valid.key" > {{$t( 'Generic.Errors.Min2Chars' )}}</span>
                </div>
              </div>
            </div>
            <div class="uk-margin-small">
              <label class="uk-form-label" for="app-key-value">{{ $t( 'Pages.Application.DevKeyValue' ) }} <span class="uk-text-danger">*</span></label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <textarea class="uk-input uk-textarea" id="app-key-value" type="text" v-model.trim="form.app.value"
                    :placeholder="$t( 'Pages.Application.Placeholder.DevKeyValue' )" style="line-height:25px"
                    :class="{ 'invalid-border': !form.valid.value }" @keyup="validateInput( 'value' )"></textarea>
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.valid.value" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.valid.value" > {{$t( 'Generic.Errors.Min2Chars' )}}</span>
                </div>
              </div>
            </div>
            <div class="uk-margin-small">
              <label class="uk-form-label" for="app-key-visibility">{{ $t( 'Pages.Application.DevKeyVisibility' ) }} <span class="uk-text-danger">*</span></label>
              <div class="uk-form-controls">
                <div>
                  <div class="uk-button-group uk-width-1-1" id="app-key-visibility">
                    <button class="uk-width-1-3" :class="btnVisibilityApplication" @click.prevent="toggleKeyVisibility('application')">{{ $t( 'Pages.Application.KeyVisibilityApplication' ) }}</button>
                    <button class="uk-width-1-3" :class="btnVisibilityPrivate" @click.prevent="toggleKeyVisibility('private')">{{ $t( 'Pages.Application.KeyVisibilityPrivate' ) }}</button>
                    <button class="uk-width-1-3" :class="btnVisibilityPublic" @click.prevent="toggleKeyVisibility('public')">{{ $t( 'Pages.Application.KeyVisibilityPublic' ) }}</button>
                  </div>
                  <div class="tooltip" v-if="!form.valid.visibility">
                    {{ $t( 'Pages.Application.Validation.OptionMissing' ) }}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" type="button">{{ $t( 'Actions.Cancel' ) }}</button>
          <button v-if="!saving" class="uk-button uk-button-primary" type="button" @click.prevent="saveNewKey();">{{ $t( 'Actions.Save' ) }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
  name: "ApplicationDeveloper",
  components: {},
  props: {
    appData: {
      type: Object
    }
  },
  data() {
    return {
      btnVisibilityApplication: 'uk-button uk-button-default',
      btnVisibilityPrivate: 'uk-button uk-button-default',
      btnVisibilityPublic: 'uk-button uk-button-default',
      form: {},
      saving: false,
      selectedKey: {}
    };
  },
  computed: {
    devKeys() {
      return this.appData?.allData || [];
    }
  },
  beforeMount() {
    this.form = this.defaultFormData();
  },  
  beforeDestroy () {
    const modalElement = document.getElementById('modal-newappkey')
    if (modalElement) {
      window.UIkit.modal('#modal-newappkey').$destroy(true);
    }
  },
  mounted() {
    window.UIkit.util.on(document, 'beforehide', '#modal-newappkey', () => {
      this.btnVisibilityApplication = 'uk-button uk-button-default';
      this.btnVisibilityPrivate = 'uk-button uk-button-default';
      this.btnVisibilityPublic = 'uk-button uk-button-default';
      this.form = this.defaultFormData();
    });
  },
  methods: {
    defaultFormData() {
      return {
        app: {
          key: '',
          value: '',
          visibility: ''
        },
        valid: {
          key: true,
          value: true,
          visibility: true
        }
      };
    },
    validateInput( field ) {
      let isValid = false;
      const value = this.form.app[ field ].trim();
      if ( value.length >= 2 ) {
        isValid = true;
      }
      this.form.valid[ field ] = isValid;
      return isValid;
    },
    async saveNewKey() {     
      const name = this.validateInput( 'key' );
      const value = this.validateInput( 'value' );
      const visibility = this.validateInput( 'visibility' );
      if ( name && value && visibility ) {
        this.saving = true;
        if ( this.selectedKey.key ) {
          await this.deleteSelectedAppDevKey();
          this.selectedKey = {};
        }
        const appKeyID = await this.$store.dispatch( 'saveOrgAppDeveloperKey', {
          appId: this.appData.id,
          inputFields: this.form.app
        } );
        this.saving = false;
        if ( appKeyID ) {
          window.UIkit.modal( '#modal-newappkey' ).hide(true);
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Application.Notification.DevKeySavedSuccessfully' ) );
          this.selectedKey = this.form.app
        }
      }
    },
    editAppKey( index ) {
      this.selectedKey = this.appData.allData[ index ];
      this.form.app = { ...this.selectedKey };
      this.toggleKeyVisibility( this.form.app.visibility.toLowerCase() );
      window.UIkit.modal( '#modal-newappkey' ).show();
    },
    deleteAppKey( index ) {
      this.selectedKey = this.appData.allData[ index ];
      window.UIkit.modal.confirm( this.$t( 'Pages.Application.DeleteDevKey', { name: this.selectedKey.key }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }} ).then(async () => {
        await this.deleteSelectedAppDevKey();
        this.selectedKey = {};
        Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Application.Notification.DevKeyDeleteduccessfully' ) );
      }, function () {
        
      });
    },
    async deleteSelectedAppDevKey() {
      const resp = await this.$store.dispatch( 'saveOrgAppDeveloperKey', {
        appId: this.appData.id,
        inputFields: {
          ...this.selectedKey,
          value: null
        }
      } );
      return resp;
    },
    toggleKeyVisibility( type ) {
      this.form.valid.visibility = true;
      if ( type === 'application' ) {
        this.btnVisibilityApplication = 'uk-button uk-button-secondary';
        this.btnVisibilityPrivate = 'uk-button uk-button-default';
        this.btnVisibilityPublic = 'uk-button uk-button-default';
        this.form.app.visibility = 'APPLICATION';
      } else if ( type === 'private' ) {
        this.btnVisibilityApplication = 'uk-button uk-button-default';
        this.btnVisibilityPrivate = 'uk-button uk-button-secondary';
        this.btnVisibilityPublic = 'uk-button uk-button-default';
        this.form.app.visibility = 'PRIVATE';
      } else if ( type === 'public' ) {
        this.btnVisibilityApplication = 'uk-button uk-button-default';
        this.btnVisibilityPrivate = 'uk-button uk-button-default';
        this.btnVisibilityPublic = 'uk-button uk-button-secondary';
        this.form.app.visibility = 'PUBLIC';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  color: #e69393;
}
.uk-button-secondary {
  background-color: #000;
  color: #fff;
}
</style>